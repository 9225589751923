<script setup>
import { Head, Link } from "@inertiajs/vue3";
import { inject } from "vue";

const route = inject("route");
defineProps({
  canLogin: Boolean,
  canRegister: Boolean,
  laravelVersion: String,
  phpVersion: String
});
</script>

<template>
  <div>
    <Head title="Welcome" />

    <div
      class="bg-gray-500-100 items-top relative flex min-h-screen justify-center dark:bg-gray-800 sm:items-center sm:pt-0"
    >
      <div class="fixed right-0 top-0 hidden px-6 py-4 sm:block">
        <p class="font-xl text-white">{{ $t('Dashboard') }}</p>
        <!-- <Link
        v-if="$page.props.user"
        :href="route('dashboard')"
        class="text-sm underline text-gray-500-700"
      >
        Dashboard
        </Link>-->

        <!-- <template v-else> -->
        <Link :href="route('login')" class="text-gray-500-700 text-sm underline">Log in</Link>

        <Link
          v-if="canRegister"
          :href="route('register')"
          class="text-gray-500-700 ml-4 text-sm underline"
        >Register</Link>
        <!-- </template> -->
      </div>
    </div>
  </div>
</template>
